
*{
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.hero-mid h1{
  padding-top: 4rem o !important;
}
.hero{
    width: 100%;
    height: 100vh;
    position: relative; 
    overflow: hidden;
    z-index: -11;
}

.hero-mid{
    width: 100%;
    height: 70vh;
    position: relative;
}

.hero-mid h1{
    padding-top: 4rem !important;
}

img{
    width: 100%; 
    height: 100%;
    object-fit: cover; 
    filter: brightness(85%);
    
}




.hero {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

img {
  height: 100%; 
  width: 100%;
object-fit: cover;
}

.hero-text {
  position: absolute;
  top: 55%;
  left: 20vw;
  transform: translate(-50%,-50%);
  width: 100%;

}


.hero-text h1 {
  background: #fff;
  font-size: 3rem;
  font-weight: 800;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-text p {
  color: #fff;
  font-size: 1.3rem;
  padding: 0.5rem 0 2rem 0;
}

.hero-text .hide{
    display: none;
}

.hero-text .show{
  background: #fff;
  font-size: 1.1 rem;
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: bold;
  border-radius: 6px;
  color: #222;
  letter-spacing: 2px;
}


@media screen and (max-width: 855px) {
  .hero-text .show {
    background: #fff;
    font-size: 1.1 rem;
    padding: 1rem 2rem;
    text-decoration: none;
    font-weight: bold;
    border-radius: 6px;
    color: #222;
    letter-spacing: 2px;
  }
 
  .hero-text h1 {
    padding: 10px 20px;
    /* left: 80%; */
  }
.landing-image{
  filter: brightness(80%);
}

 
  .hero-text p {
    font-size: 1.1rem;
    padding: 0 0 2rem 0;
  }

  .hero-text .show {
    font-size: 1rem;
    padding: 0.6rem 1.1rem;
    border-radius: 6px;
  }
  .hero-text {
    position: absolute;
    /* top  : -60%; */
   left: 50%;
    /* transform: translate(-50%, -90%); */
    width: 100%;
  }
}