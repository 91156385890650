.desc-about {
  margin: 4rem 6rem;
    border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.09);
  padding: 30px;
  height: auto;
}

.desc-about h1 {
  font-size: 3rem;
}

.desc-first-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5rem;
  text-align: center;
  
}

.desc-first-des p {
  text-align: center;
  width: 100%;
  padding-right: 15px;
  /* padding-top: -20px; */
}

.desc-des-text{
  width: 50%;
  text-align: justify;
  font-size: 1.1rem;
  /* height: 20rem; */
}

.desc-image {
  position: relative;
  /* position: absolute;  */
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.desc-image img {
  width: 49%;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.desc-image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

@media screen and (max-width: 850px) {
  .desc-about {
    margin: 4rem 2rem;
    height: auto;
  }

  .desc-first-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2.5rem;
  }

  .des-text{
    width: 100%;
  }
  .desc-des-text p{
    padding: 9px;
    width: 100%;
    /* height: 20rem; */
  }

  .desc-des-text{
    width: 100%;
    /* height: 20rem; */
  }

 
  
  .desc-image{
    width: 100%;
    margin: 1.8rem 0;
  }
  .desc-image img{
    height: 250px;
  }
}
