

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 50px;
}

.description{
    padding-top: 40px;
    text-align: center;
}

#accomo{
  padding-top:50px ;
  text-align: left;
  margin-left: 6vw;
}

#dining{
  padding-top:50px ;
  text-align: left;
  margin-left: 9vw;
}

#events{
  padding-top:20px ;
  text-align: left;
  margin-left: 9vw;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 350ms ease;
}

.gallery .pics:hover {
  filter: brightness(70%);
}

.imageholder {
  width: 100%;
}
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  width: 100%;
  height: 100vh;
  visibility: visible;
  opacity: 1 ;
  transform: scale(1);
}



.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}

@media screen and (max-width: 855px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  #accomo{
    padding-top:50px ;
    text-align: center;
  
  }
  
  #dining{
    padding-top:50px ;
    text-align: center;
    
  }
  
}

@media screen and (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
  #accomo{
    padding-top:50px ;
    text-align: center;
  
  }

  .scrolled{
    color: rgb(0, 0, 0);
  }
  
  #dining{
    padding-top:50px ;
    text-align: center;
    
  }
}
