.videoholder {
  padding-top: 30px;
}

.videoholder h1 {
  padding-bottom: 30px;
  font-size: 2.5rem;
}
.video-area {
  height: 100vh;
}

.video {
  height: 80vh;
  width: 92vw;
  display: block;
  padding-left: 7%;
  padding-bottom: 2.5rem;
}

.view-more-btn {
  /* padding-top: 4rem; */
  background: #eed6a1;
  
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: bold;
  border-radius: 20px;
  color: #222;
  margin-bottom: 4rem;
  letter-spacing: 2px;
}

@media screen and (max-width: 855px) {
  .videoholder {
    padding-top: -100px;
  }
  .video-area {
    height: 70vh;
  }

  .video {
    height: 50vh;
    width: 92vw;
  }
}
