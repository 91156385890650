.trip {
    margin-top: 4rem 6rem;
    color: #2a2a2a;

}


.trip h1 {
    font-size: 3rem;
}

.tripcard {
    margin-top: 3rem;
    padding: 40px;
    display: flex;

    justify-content: space-between;
}


.t-card {
    width: 32%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, .1);

    border-radius: 40px;
    cursor: pointer;
    padding: 1rem .8rem;
    border: solid 1px rgba(76, 144, 76, 0.455);
    background-color: rgba(0, 0, 0, 0.09);
}

.t-card h2 {
    padding-bottom: 20px;
    text-align: center;
    padding-top: 20px;
}

.t-image {
    height: 300px;
    margin: auto;
    overflow: hidden;

    border: 1px solid green;
    width: 300px;
    /* padding: 0 20px; */
    border-radius: 50%;
}

.t-image img {
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.t-image:hover img {
    transform: scale(1.3);
}

.t-card h4 {
    font-size: 1.rem;
    padding: .9rem 0 .2rem 0;
}

@media screen and (max-width: 1140px) {
    .trip {
        margin: 4rem 1.5rem;
    }

    .tripcard {
        /* margin-top: 3rem;
      display: flex;
      justify-content: space-between; */
        padding-top: 10px;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        flex-direction: column;
    }

    .t-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }


    .t-image {
        height: 300px;
        margin: auto;
        overflow: hidden;

        border: 1px solid green;
        width: 300px;
        /* padding: 0 20px; */
        border-radius: 50%;
    }

}

@media screen and (max-width: 1140px) {
    .trip {
        margin: 4rem 1.5rem;
    }

    .tripcard {
        /* margin-top: 3rem;
      display: flex;
      justify-content: space-between; */
        padding-top: 10px;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        flex-direction: column;
    }

    .t-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .t-image {
        height: 400px;
        margin: auto;
        overflow: hidden;

        border: 1px solid green;
        width: 400px;
        /* padding: 0 20px; */
        border-radius: 50%;
    }



}


@media screen and (max-width: 520px) {
    .trip {
        margin: 4rem 1.5rem;
    }

    .tripcard {
        /* margin-top: 3rem;
      display: flex;
      justify-content: space-between; */
        padding-top: 10px;
        padding: 20px;
        margin-left: 5px;
        margin-right: 5px;
        flex-direction: column;
    }

    .t-card {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .t-image {
        height: 265px;
        margin: auto;
        overflow: hidden;

        border: 1px solid green;
        width: 265px;
        /* padding: 0 20px; */
        border-radius: 50%;
    }

}