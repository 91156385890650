.about-container{
    margin: 4rem 6rem;
    text-align: start;
    text-align: center;
}

html {
   scroll-behavior: smooth;
 }
.about-container h1{
   padding-bottom: 1rem;
}

.about-container p{
    padding-bottom: 2rem;
 }

 @media screen and (max-width: 855px) {
    .about-container{
        margin: 4rem 2rem;
    }
    
    /* .about-container h1{
       padding-bottom: 1rem;
    } */
 }