.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 4rem 6rem;
  background-color: #242424;
  color: #fff;
}



.column {
  flex: 1;
}

.row {
  display: flex;
}

.social-icons {
  display: flex;
  align-items: center;
}

.icons a {
  text-decoration: none;
  color: #fff;
  font-size: 1.5rem;
  margin: 0 1rem;
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #01959a;
}

.map-section {
  margin-bottom: 1rem;
  padding-top: 20px;
}

.map {
  width: 70%;
  height: 300px;
  border: none;
}

.section {
  flex: 1;
  padding: 1rem;
}

.section h4 {
  margin-top: 0;
  font-size: 1rem;
  padding-bottom: 0.8rem;
}

.section a,
.section p {
  color: #d4d4d4;
  text-decoration: none;
  line-height: 1.5;
}

.section a:hover {
  color: #01959a;
}

/* Responsive styles */
@media screen and (max-width: 850px) {
  .footer {
    flex-direction: column;
    padding: 2rem;
  }

  .column {
    margin-bottom: 2rem;
  }

  .section {
    padding: 1rem 0;
  }
}